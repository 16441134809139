import {mapGetters, mapMutations, mapActions} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import keyBy from 'lodash/keyBy';
//sections

export default {
    name: "map",
    components: {},
    directives: {
        onClickaway: onClickaway,
    },
    data() {
        return {
            showPersonCard: false,
            showPersonIfo: false,
            personsList: [
                {
                    color: '#E82FF2',
                    name: 'Застиглик',
                    position: 4,
                    description: 'Стою поміж двох валів – Верхнього та Нижнього, у кінці довжелезного скверу.',
                    title: 'Застиглика непросто було відірвати від екрана. У нього там було все – школа, друзі та все, що потім. А після того, як на вулиці стало не дуже безпечно, Застиглик взагалі “переселився” в гаджети.\n',
                    desc: 'Та якось Застиглик забув зарядити батарейку, коли вдома вимкнули світло. Гра обірвалась на найцікавішому – герой мав спуститись у підземелля. Щоб не нудьгувати, Застиглик почав малювати вхід до печери. Додав чоловічка. Допоміг йому “спуститись”, домалювавши сходи. Дав чоловічку палку. Хоча ні – два мечі. А тепер монстр!\n' +
                        'Застиглик так захопився, що не помітив, коли світло з’явилось. А потім показав пригоду друзям. І їм вона сподобалась навіть більше, ніж у грі. Застиглик розрухався і вирішив не зупинятись – став додумувати закінчення гри частіше, ніж проходити в гаджеті. Тепер ніхто б і не повірив, що колись Застиглик тільки в екрані й сидів. \n'
                },
                {
                    color: '#3A85FF',
                    name: 'Несонька',
                    position: 5,
                    description: 'Шукай на розі Києво-Могилянської академії, біля пам’ятника видатного українського філософа-містика, якого в народі ще називали “Сократом”.',
                    title: 'Першою з усіх завжди засинала Несонька. За день вона видумувала стільки пригод з друзями, що потім тільки-но торкалась подушки – і вже міцно спала.\n',
                    desc: 'Та якось батьки сказали, що гуляти небезпечно, друзі перестали виходити, а на вулиці гуділи гидкі сирени.\n' +
                        'Несонька не знала, що сталось, тому додумала собі сама – то щось недобре. Через це їй почали снитись жахи. Щоб не бачити їх, вона довго крутилась у ліжку і не спала до самісінького ранку. А вдень у неї зовсім не було сил.\n' +
                        'Якось Несонька заснула просто на уроці. Батьки спитались, що сталось – і Несонька розповіла їм все: що їй бракує біганини з друзями, що уявляє різні страхіття і потім не може заснути. Тоді батьки поділились з нею всім, що знали: що дорослим спокійніше, коли діти поруч, що сирени не хочуть нікого лякати – просто гучно попереджають, щоб всі почули й встигли сховатись. І найважливіше – що Несонька завжди може про все спитати, щоб не додумувати поганого. Бо краще видумувати щось приємне.\n' +
                        'Вкладаючись у ліжечко, Несонька вже й забула про жахи та “замовила” собі такий сон, як хотіла. А перед школою розповіла батькам, що все вдалось. І з купою сил побігла на урок.\n'
                },
                {
                    color: '#00BB8A',
                    name: 'Турботлик',
                    position: 3,
                    description: 'Дивлюсь на Дніпро з Володимирського узвозу. Шукай пам’ятник великого князя київського та спускайся гіркою вниз, я тут чекатиму!',
                    title: 'Турботлик сам бачив, як все змінилось. Справ у всіх побільшало, як і людей. Тато з мамою прийняли багато гостей і всі вони ледь поміщались у будиночку. Більшість з них Турботлик бачив вперше, але зовсім не боявся. Просто переживав, що про нього всі забули.\n',
                    desc: 'Якось він сидів та дивився на дорослих, поки вони носились туди-сюди. А потім побачив, що навпроти нього хтось теж сидів самотній. І ще трошки далі теж. Таких було багато по всьому будиночку – може, вони теж боялись, що про них всі забули. Але Турботлик не знав, як з ними бути.\n' +
                        'Тоді він подивився на маму з татом і сам почав робити, що міг: ділився іграшками, говорив щось добре та обов’язково обіймав. Так всі відчули себе потрібними й більше не боялись, що їх покинули.\n' +
                        'Люди потім їхали далі, а Турботлик готував свої маленькі обійми для нових. Просто щоб нагадати, що коли всі разом, то нічого не страшно.\n'
                },
                {
                    color: '#45DEFF',
                    name: 'Мовчатко',
                    position: 10,
                    description: 'У мене серйозна компанія – поруч пам’ятник войовничій княгині, яка помстилась ворогам за свого чоловіка.',
                    title: 'Мовчатко не завжди був таким мовчазним. Може, навіть навпаки. Тому зараз його тато так переживав і намагався щось зробити. Поміж роботою він запитував: Хочеш морозива? У школі все добре? Можемо щось пограти, що скажеш? Але Мовчатко багато не розповідав, а тато не вмів вгадувати думок.\n',
                    desc: 'Якось після школи Мовчатко зустрів друга. Той сидів на гойдалці, сумував і колупав кросівком пісок. Мовчатко спитав, що сталось і друг поділився журбинкою. А потім несподівано сказав: “Фух, дякую!”\n' +
                        'Мовчатко здивувався, бо нічого не зробив – просто слухав. А коли прийшов додому, то й сам вирішив не мовчати на татове “як справи”. Розповів, що сумує за мамою і шкодує, що проспав той ранок, коли вона поїхала їх захищати. І відчув, що таки “фух”.\n' +
                        'Того вечора вони з татом відклали все: пізню роботу, прибирання, навіть ремонт пралки. Наготували довгих-предовгих макаронів з фрикадельками й так само довго говорили про все на світі. Бо коли добре поговорити, то таки “фух”!\n'
                },
                {
                    color: '#F00E0E',
                    name: 'Додомця',
                    position: 7,
                    description: 'Прямуй в сторону Софіївського собору – я буду одразу по дорозі, біля Південної в’їзної вежі.',
                    title: 'Додомця любила пакувати валізу і їздити в невідомі краї з братом, мамою і татом. Там вони міряли яскраві шльопанці чи солом’яні капелюхи та замовляли триповерхове морозиво.\n',
                    desc: 'Тільки того разу все було не так – вони поїхали й ніяк не повертаються. Додомця вже й не знає, скільки часу пройшло. Ще й кота Лапатого взяли, а такого раніше не було. Тож вона не витримала і скликала сімейну нараду. Хотілось сьогодні ж зібрати всі речі й разом поїхати додому.\n' +
                        'Додомця сказала, що хоче свою коробку іграшок, помаранчевий чайник на кухні, ванну, повну бульбашок і щоб кіт мав свою дряпку. Всі погодились, що скучили за тими речами. А потім мама сказала, що може цей дім і не схожий на наш, але ми можемо його зробити нашим: теж знайдемо собі особливий чайник, купимо Лапатому дряпку і влаштуємо пінну ванну. Хоча б спробувати можна.\n' +
                        'Додомця трішки подумала і погодилась. Зрештою, найбільше їй подобалось, що вони знову будуть робити щось разом. Тому що саме це дарувало їй відчуття того, що вона вдома. А речі знайдуться і тут.\n'
                },
                {
                    color: '#FFCC16',
                    name: 'Дзиглик',
                    position: 2,
                    description: 'Я примостився дуже близько до Майдану Незалежності. На головній вулиці Києва! А поряд плавають медузки.',
                    title: 'Дзиглику було важко всидіти на місці після того ранку, коли батьки неймовірно швидко зібрались, посадили його в авто і поїхали. Довкола було стільки руху – всі кудись їхали, щось миготіло, хтось сигналив. В дорозі він сунувся від одного вікна до іншого, у новій домівці втікав з-за столу, а в школі чекав, коли вже закінчиться урок. Крутився на всі боки – сам не вчився й іншим не давав.',
                    desc: 'Вчитель на нього не злився, але запропонував угоду: якщо хоча б 10 хвилин зможеш зосередитись, то відпущу додому. Дзиглику угода сподобалась. Але сталось дивне – як тільки час закінчився, йому вже не хотілось йти з уроку. Так і висидів до кінця. \n' +
                        'Вдома він повторив експеримент – цілих 10 хвилин складав конструктор і не помітив, як все склав. Потім цілих 10 хвилин робив уроки, а все вже готово. Ще й рюкзак до школи зібрав.\n' +
                        'Він дістав непрочитані комікси, розкиданий пазл і навіть потренувався стояти на руках – все вдалось.\n' +
                        'Непосидючість у Дзиглика залишилась, але він знав, що вже може тримати її під контролем. Більше, ніж 10 хвилин. І дуже-дуже цим пишався.\n'
                },
                {
                    color: '#2428FF',
                    name: 'Скиглик',
                    position: 6,
                    description: 'Йди до Золотих Воріт, а потім приглядайся – я на перехресті.',
                    title: 'Скиглику дуже подобалось бути зайнятим. Та коли вони всім сімейством переїхали, справ у нього стало зовсім мало.',
                    desc: 'Зате в інших справ було повно – мама з татом працювали, дідуся майже не було вдома, а бабуся пакувала коробки волонтерам. Навіть в кота був розклад: полежав, поїв, заснув, знову поїв. Скиглик нудився, але й пожалітись не було кому – хіба самому собі.\n' +
                        'Все змінилось, коли Скиглик знайшов таємний лист. Він, звісно, впізнав бабусин почерк, але не зізнався. Це було коротке послання: “Твій план на сьогодні – скласти план. Ввечері чекаю на звіт”. Скиглик аж завив, це жарт?\n' +
                        'А виявилось непросто і зайняло майже весь день. Довелось походити по будинку й добряче подумати. Зрештою, список виглядав приблизно так: “Скласти з космічного корабля – піратський, знайти всі чорні шкарпетки, побігати на другому поверсі, намалювати яблуко для бабусиного пирога, відкрити п’ять книжок на 43 сторінці, дізнатись, скільки разів на день їсть кіт”. Таємний агент бабуся оцінила!\n' +
                        'Наступного ранку Скиглик теж отримав лист. Видумати план – нелегке завдання, зате більше не залишилось часу жалітись на нудьгу. Скиглик навчився придумувати собі заняття сам. \n'
                },
                {
                    color: '#31E14C',
                    name: 'Хтознасіо',
                    position: 8,
                    description: 'З одного боку – Національний академічний театр, з іншого – поважний бородатий чоловік у кріслі, історик. Ось на цьому відрізку вулиці й шукай.',
                    title: 'Спочатку Хтознасіо знав, про що мріє – дорости до змагань на новісінькому стадіоні, який тільки відкрили у його місті. Ще рік і вже мали б пустити. Ну, сталось не так, як він собі думав. Тепер вони з мамою в іншому місті, та й зі стадіону нічого не залишилось.',
                    desc: 'Хтознасіо почувався розгубленим. Йому здавалось, що іншої мрійки не знайти. Може, ти хочеш записатись на флейту? Хтозна. І так з усім.\n' +
                        'Хтознасіо ходив до школи, але не знав, який урок подобається найбільше. “Зате знаю, який улюблений пиріжок зі столової. Але пиріжок не може бути мрією!”, – думав він.\n' +
                        'Хтознасіо сів у парку і розсипав крихти від пиріжка біля ніг. Серед усіх пташок, які злетілись, була одна незвична – сіренька та дуже жвава. І коли він спитав себе, що це за пташка, то байдужого “хтозна” не було. Хтознасіо помчав додому, щоб роздивитись всіх сірих пташок в книжці про пташок.\n' +
                        'Може, наступного тижня йому будуть цікаві мотоцикли, а не пташки, але тепер Хтознасіо точно знав, що мрійки можуть знайтись всюди.\n'
                },
                {
                    color: '#7A43FF',
                    name: 'Напотемко',
                    position: 9,
                    description: 'Знаєш, де великий Парус? Ходи сюди, я на зупинці чекатиму. Точно побачиш!',
                    title: 'Раніше Напотемко не знав, що це таке – коли «темно». Вдень світило сонце, по вечорах бігало світло фар чи блимали вуличні ліхтарики. А коли блимали світлофори, взагалі було свято! Зелений, жовтий, червоний.\n',
                    desc: 'Та якось Напотемко прокинувся від незвичного світла – спалахів у небі. А після них стався страшенний гуркіт. Ще ніколи Напотемко не бачив стільки автівок і фар, що швидко їхали геть. У місті стало порожньо, а ввечері чомусь не ввімкнулись ліхтарики. Стало «темно».\n' +
                        'Напотемко злякався. Минали дні, а він ніяк не міг оговтатись – з жахом чекав ночі, проти якої не було найменшого світелка.\n' +
                        'Та якось вдень Напотемко помітив, що будинки поруч більше не сірі. І аж ніяк не збирались сіріти. Невже їх не лякає «темно»? Він роздивлявся їх до самого вечора, намагаючись зрозуміти, як їм це вдалось. А тоді побачив у вікнах світло. Коли придивився, не повірив – до світла сходились люди. Виявляється, воно там завжди було – всередині кожного будинку.\n' +
                        'Напотемко був надто маленьким, щоб помістити у собі хоч одну людину. Але йому дуже хотілось знайти своє світелко! “Тоді я б міг не тільки сам не боятись, а й чийсь страх прогнати”, – засинав він собі з думкою. «Темно» більше не лякало його, а сам він тепер ніколи не почувався самотнім.\n'
                },
                {
                    color: '#F97621',
                    name: 'Тремплик',
                    position: 1,
                    description: 'Тут звучить орган і живе святий Миколай під величезним дахом.',
                    title: 'Для Тремплика ця мандрівка перевернула все з ніг на голову. Він майже не взяв своїх іграшок, забув щасливу кепку і деколи не розумів місцевих говірок. Ще й у нову школу йти.\n',
                    desc: 'Взагалі Тремплик не був проти подорожі – у його рідному місті ставало занадто гучно, над головою щось свистіло і друзі роз’їхались хто куди. Але у новій місцині дуже переживав і соромився з кимось знайомитись. Йому здавалось, що всі тут геть інші, через що почувався самотньо.\n' +
                        'Якось він зупинив м’яч, який ледь не вилетів на дорогу і до нього збіглись діти. Подякували та спитали, як його звати. “Тремплик”, – сказав він, страшенно хвилюючись і вже хотів бігти далі, аж тут його покликали гратись. Наступного дня теж. І ще наступнішого.\n' +
                        'Дітям було цікаво з ним, а йому – з дітьми. У них були схожі ігри, подібно жартували, часом однаково нудились на уроках. Іноді реготали з нових слів одне одного, але точно не зі зла. І Тремплик відчув себе своїм.\n'
                }
            ]
        }
    },
    created() {

    },
    mounted() {

        this.getPersonsData().then(() => {
            this.getOpenedPerson()
        })

    },
    computed: {
        ...mapGetters({
            personInfo: 'popups/personInfo',
            personageData: 'game/gameData',
        })
    },

    methods: {
        ...mapMutations({
            showPerson: 'popups/SHOW_PERSON',
            setPersonInfo: 'popups/SET_PERSON_INFO',
        }),
        ...mapActions({
            getPersonsData: 'game/GET_GAME_DATE',
            resetCards: 'game/RESET_CARDS'
        }),
        away: function () {
            this.closePopup()
        },
        showPersonPopup(item) {
            if (!item.isOpened) {
                this.showPerson(true)
                this.showPersonCard = true
                this.setPersonInfo(item)
                setTimeout(()=>{
                    if (window.innerWidth > 500) {
                        this.$refs.cardPopup.classList.toggle('rotateCard')
                    }
                },1000)
                document.getElementById('html').classList.add('hide')
            } else {
                this.setPersonInfo(item)
                this.showPersonIfo = true
            }


        },
        rotateCard() {
            if (window.innerWidth > 500) {
                this.$refs.cardPopup.classList.toggle('rotateCard')
            }

        },
        closePopup() {
            this.showPersonCard = false
            this.showPersonIfo = false
            this.setPersonInfo('')
            document.getElementById('html').classList.remove('hide')
        },

        getOpenedPerson() {
            let arr = [];

            let personageData = keyBy(this.personageData, ({number}) => number);

            this.personsList.forEach((storedItem, idx) => {
                let newItem = Object.assign({}, personageData[storedItem.position], storedItem);

                arr.push(newItem)
            })


            arr.forEach(el => {

                let hat = document.getElementById(`hat${el.position}`).getBoundingClientRect();

                if (el.isOpened) {
                    document.querySelector(`.Hero${el.position}`).classList.remove('hideElement')
                    document.querySelector(`.Hero${el.position}`).style.top = (hat.top - 40) + 'px'
                    document.querySelector(`.Hero${el.position}`).style.left = (hat.left - 40) + 'px'

                } else {
                    document.querySelector(`.hat${el.position}`).classList.remove('hideElement')
                    document.querySelector(`.hat${el.position}`).style.top = (hat.top - 40) + 'px'
                    document.querySelector(`.hat${el.position}`).style.left = (hat.left - 40) + 'px'
                }

            })
            this.personsList = arr

        },

    }
}
